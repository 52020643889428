/*doc
---
title: Buttons
name: 01-Buttons
category: Form Elements-Buttons
---

Buttons by default are green, but can be made dark gray by using `.secondary`.

```html_example

<button>Apply</button>

<button class="secondary">Cancel</button>

<button class="secondary" disabled>Disabled</button>
```

*/

%base_button_styles {
  box-shadow: none;
  color: #fff;
  background-color: $primary-color;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  overflow: hidden;
  padding: 15px 10px;
  border: 0 none;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  min-width: 145px;
  transition: all 0.3s;
  text-decoration: none;
  line-height: 1;
  font-weight: bold;

  @include breakpoint(mobileonly) { 
      display: block; 
      width: 100%;
      margin: 10px auto;
  }

   @include breakpoint(phablet) { 
      display: inline-block; 
      width: auto;
      margin: 5px auto;
  }

  &:hover {
    background-color: $primary-color-hover;
  }

  &.secondary {
    background-color: $secondary-color;
    font-weight: normal;

    &:hover {
      background-color: $secondary-color-hover;
    }

  }

  &:disabled {
    background: #E5E5E5;
    color: #9B9B9B;
    cursor: not-allowed;

    &:hover {
      background: #E5E5E5;
      color: #9B9B9B;
    }

  }

  span.icon {
    padding-right: 5px;
    position: relative;
    top: -2px;
  }

}

button {
  @extend %base_button_styles;
}