.toggle-reveal-target {
  transition: all 0.5s;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  margin: 0;

  &.no-fade {
    display: none;
  }

  &.revealed {
    max-height: 3000px;
    opacity: 1;
    margin: auto;
    display: block;
  }

}

tr.toggle-reveal-target {
  display: none !important;

  &.revealed {
    display: table-row !important;
  }

}

.toggle-disable-target, .disabled {
  transition: all 0.5s;
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed;

  &.hide-on-disable {
    display: none;
  }

  &.revealed {
    opacity: 1;
    pointer-events: auto;
    cursor: auto;
  }

}

  .disabled-override{
    @extend .disabled;
  }
