/*doc
---
title: Left Navigation
name: 02-left-nav
category: Basics-Left Nav
---

The primary navigation sits on the left-hand side of the page and should be surrounded by `div.col-md-3`.

```html_example
<nav id="left-nav">
    <a href="index.html" class="logo">
        <img src="/assets/img/logo-white.png" alt="Araknis Systems">
    </a>
    <ul>
        <li class="has-sub active">
            <label for="status"><span class="icon icon-list"></span> Status</label>
            <input type="checkbox" id="status" checked>
            <ul class="sub-menu">
                <li><a class="active">System</a></li>
                <li><a href="#">Clients & Services</a></li>
                <li><a href="#">Ports</a></li>
            </ul>
        </li>
        <li class="has-sub">
            <label for="settings"><span class="icon icon-sound-mix"></span>Settings</label>
            <input type="checkbox" id="settings">
            <ul class="sub-menu">
                <li><a href="#">System</a></li>
                <li><a href="#">WAN</a></li>
                <li><a href="#">LAN</a></li>
                <li><a href="#">Firewall</a></li>
                <li><a href="#">DDNS</a></li>
                <li><a href="#">Port Forwarding</a></li>
                <li><a href="#">Security</a></li>
            </ul>
        </li>
        <li class="has-sub">
            <label for="maintenance"><span class="icon icon-tools"></span>Maintenance</label>
            <input type="checkbox" id="maintenance">
            <ul class="sub-menu">
                <li><a href="#">Ping</a></li>
                <li><a href="#">DNS Lookup</a></li>
                <li><a href="#">File  Management</a></li>
                <li><a href="#">Restart</a></li>
                <li><a href="#">Log Out</a></li>
            </ul>
        </li>
        <li class="has-sub">
            <label for="advanced"><span class="icon icon-cog"></span>Advanced</label>
            <input type="checkbox" id="advanced">
            <ul class="sub-menu">
                <li class="has-sub">
                    <label for="routing">Routing</label>
                    <input type="checkbox" id="routing">
                    <ul class="sub-menu">
                        <li><a href="#">Static Route</a></li>
                        <li><a href="#">Port Triggering</a></li>
                        <li><a href="#">DMZ</a></li>
                        <li><a href="#">1:1 NAT</a></li>
                    </ul>
                </li>
                <li><a href="#">VLANS</a></li>
                <li class="has-sub">
                    <label for="VPN">VPN</label>
                    <input type="checkbox" id="VPN">
                    <ul class="sub-menu">
                        <li><a href="#">Status</a></li>
                        <li><a href="#">Open VPN</a></li>
                        <li><a href="#">PPTP</a></li>
                        <li><a href="#">VPN Passthrough</a></li>
                        <li><a href="#">Gateway to Gateway</a></li>
                        <li><a href="#">Client to Gateway</a></li>
                    </ul>
                </li>
                <li><a href="#">IPV6</a></li>
                <li><a href="#">Local DNS</a></li>
                <li><a href="#">SNMP</a></li>
                <li><a href="#">ACLS</a></li>
                <li class="has-sub">
                    <label for="QoS">QoS</label>
                    <input type="checkbox" id="QoS">
                    <ul class="sub-menu">
                        <li><a href="#">CoS/DSCP Settings</a></li>
                        <li><a href="#">DSCP Markings</a></li>
                        <li><a href="#">Rate Limiting</a></li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
<div class="col-xs-12">
    <button class="btn-block" style="margin-top: 20px;">Quick Setup</button>
</div>
```

*/

#left-nav {
  color: $light-gray;
  background-color: #273142;

  a.logo {
    display: block;
    width: 100%;

    img {
      width: 100%;
      max-width: 150px;
      display: block;
      padding: 0;
      margin: 20px auto;
    }

  }

  // general nav styles
  label,
  .nav-link {
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    font-weight: bold;

    span.icon {
      position: absolute;
      font-size: 20px;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }

  }
  .sub-menu {
    padding-bottom: 10px;
    background-color: #313D4F;
  }
  .sub-menu > li {
    padding: 10px 0 0 30px;

    .sub-menu > li {
      padding: 10px 0 0 20px;
    }

  }
  li > a {
    text-decoration: none;
    color: $light-gray;
    transition: color 0.3s;

    &.active,
    &:hover {
      color: $primary-color;
    }

  }

  // highest level menu items
  > ul > li {
    padding: 10px 20px;

    > label,
    > .nav-link {
      display: block;
      font-size: 16px;
      cursor: pointer;
    }

    &.has-sub {
      padding: 0;
      position: relative;

      > label {
        padding: 10px 0 10px 30px;
      }

      &.active:after {
        content: '';
        height: 100%;
        width: 5px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $primary-color;
      }

      &:hover > label {

        &:before {
          background-color: $light-gray;
          width: 100%;
        }

      }

    }

    > .sub-menu {
      border-top: 0 solid $med-gray;
    }

  }

  .nav-link {
    padding: 10px 0 10px 30px;
    color: #fff;

    &.active,
    &:hover {
      color: $primary-color;
    }

  }

  // hide checkboxes used for dropdown toggles
  li.has-sub input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
  }

  // hide dropdowns unless following checked box
  ul.sub-menu {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: all 0.5s;
    opacity: 0;
  }

  li.has-sub input[type="checkbox"]:checked + .sub-menu {
    max-height: 1000px;
    padding-bottom: 10px;
    opacity: 1;
  }

}