input:not([class*="form-control-"]):not(.switch):not(.form-check-input):not([type='checkbox']) {
  height: 38px;
}

span.password-toggle {
  position: absolute;
  right: 10px;

  // a workaround solution for error message issue
  // TODO, RWD needs check
  // bottom: 0;
  top: 0;

  display: block;
  text-decoration: none;
  cursor: pointer;

  &:after {
    font-family: 'icomoon' !important;
    content: '\e8f4';
    color: $primary-color;
    font-size: 25px;
  }

  &.visible:after {
    content: '\e8f5';
  }

}

.all-caps {
  text-transform: uppercase;
}

.form-group,
.form-inline {

  .ip-range {
    display: flex;
    align-items: center;

    input {
      width: 100%;
    }

    span {
      padding: 0 5px;
    }

  }

}

input.sm-input {
  max-width: 160px;
}

input.md-input {
  max-width: 200px;
}

.combobox{
  position: relative;
  &-select{
    width:100% !important;
  }
  &-input{
    width: calc(100% - 25px) !important;
    position: absolute;
    top: 32px;
    left:0;
    display: none !important;
    border-right:0;
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    &.show{
      display: inline-block !important;
    }
  }
}

select .hidden-option{
  display: none;
}

table{
  .combobox{
    &-input{
      top:0px;
    }
  }
}