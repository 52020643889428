.dataTables_filter {

  label {
    font-size: 0;

    input {
      font-size: 14px;
      width: 100%;
    }

  }

}

.btn.focus{
  background-color: initial;
}

.nav-pills .large-pill+.large-pill {
    margin-left:0; 
}

@include media-breakpoint-down(sm) {

  .dataTables_length {
    display: none;
  }

  .dataTables_filter {

    label {
      width: 100%;

      input {
        font-size: 14px;
        width: 100% !important;
      }

    }

  }

  .modal .form-inline .form-control:not(.small-control){
    width:100%;
  }

}

.wifi-schedule-add-more{
    float: right;
    text-align: right;
    font-size: 14px;
    margin:20px 10px;
    cursor: pointer;
    .icon{
      display: inline !important;
    }
}
.wifi-schedule-delete-time{
  display: inline-block !important;
  padding-left: 5px;
  margin-top: -3px;  
}
.time-range{
  margin-bottom:20px;
}

.large-pill{
  width:33%;
}

@media (min-width: 576px)
{  
  .form-inline .form-control:not(.small-control){
    width:100%;
  }
}
