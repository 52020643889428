td.status-light {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: $med-gray;
  }

  &.online:after {
    background-color: $primary-color;
  }

}