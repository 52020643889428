/*doc
---
title: Headers
name: 01-Headers
category: Typography-Headers
---

The font face used is `Open Sans`.

```html_example
<h1>Header 1 / 36px</h1>
<h2>Header 2 / 30px</h2>
<h3>Header 3 / 24px</h3>
<h4>Header 4 / 20px</h4>
```

*/
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat/montserrat-regular-webfont.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat/montserrat-bold-webfont.woff") format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

h3:first-of-type {
  margin-top: 0;
}

h1 {
  font-size: 36px;
  color: $dark-gray;
}

h2 {
  font-size: 30px;
  color: $dark-gray;
}

h3 {
  font-size: 24px;
  color: $dark-gray;
}

h4 {
  font-size: 20px;
  color: $dark-gray;
}