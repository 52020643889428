.add-dhcp-server, .add-tunnel {
  height: 302px;
  width: 250px;
  margin: 0.625rem;
  position: relative;
  border-radius: .25rem;
  border: 1px solid rgba(0,0,0,0.125);
  transition: all 1s;
  cursor: pointer;

  &:hover {
    background-color: $gray-lightest;

    p {
      color: $dark-gray;
    }

  }

  p {
    padding: 1.25rem;
    font-size: 100px;
    color: $med-gray;
    text-align: center;
    line-height: 1.5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: all 1s;

    span {
      display: block;
      font-size: 14px;
      position: relative;
      top: -30px;
    }

  }

  &.openvpn,
  &.pptp {
    height: 253px;
  }

  &.wireless {
    height: 321px;
  }

}

.card.dhcp-card-sm {

  &:not(.card-no-hover) {
    cursor: pointer;
    transition: all 1s;

    &:hover {
      background-color: $gray-lightest;
    }

  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid $card-border-color;
  }

}