/*doc
---
title: Icons
name: 03-Icons
category: Typography-Icons
---

<style>
  .icon {
    font-size: 40px;
    text-align: center;
  }
  .col-md-3 .row {
    text-align: center;
  }
</style>

<div class="col-md-3">
  <div class="row"><span class="icon icon-chevron-thin-down"></span></div>
  <div class="row">icon-chevron-thin-down</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-chevron-thin-right"></span></div>
  <div class="row">icon-chevron-thin-right</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-cloud"></span></div>
  <div class="row">icon-cloud</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-cog"></span></div>
  <div class="row">icon-cog</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-clock"></span></div>
  <div class="row">icon-clock</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-gauge"></span></div>
  <div class="row">icon-gauge</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-sound-mix"></span></div>
  <div class="row">icon-sound-mix</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-tools"></span></div>
  <div class="row">icon-tools</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-list"></span></div>
  <div class="row">icon-list</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-visibility"></span></div>
  <div class="row">icon-visibility</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-visibility_off"></span></div>
  <div class="row">icon-visibility_off</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-menu"></span></div>
  <div class="row">icon-menu</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-triangle-down"></span></div>
  <div class="row">icon-triangle-down</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-triangle-up"></span></div>
  <div class="row">icon-triangle-up</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-select-arrows"></span></div>
  <div class="row">icon-select-arrows</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-lock-open"></span></div>
  <div class="row">icon-lock-open</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-lock"></span></div>
  <div class="row">icon-lock</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-autorenew"></span></div>
  <div class="row">icon-autorenew</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-info"></span></div>
  <div class="row">icon-info</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-delete"></span></div>
  <div class="row">icon-delete</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-playlist_add"></span></div>
  <div class="row">icon-playlist_add</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-power_settings_new"></span></div>
  <div class="row">icon-power_settings_new</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-exit_to_app"></span></div>
  <div class="row">icon-exit_to_app</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-flash"></span></div>
  <div class="row">icon-flash</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-cw"></span></div>
  <div class="row">icon-cw</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-share-alternative"></span></div>
  <div class="row">icon-share-alternative</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-do_not_disturb_alt"></span></div>
  <div class="row">icon-do_not_disturb_alt</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-public"></span></div>
  <div class="row">icon-public</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-content_copy"></span></div>
  <div class="row">icon-content_copy</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-wifi"></span></div>
  <div class="row">icon-wifi</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-area-graph"></span></div>
  <div class="row">icon-area-graph</div>
</div>

<div class="col-md-3">
  <div class="row"><span class="icon icon-more_vert"></span></div>
  <div class="row">icon-more_vert</div>
</div>

```none_example
```

*/

.icon.td-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?fkep4t');
  src:  url('../fonts/icons/icomoon.eot?fkep4t#iefix') format('embedded-opentype'),
        url('../fonts/icons/icomoon.ttf?fkep4t') format('truetype'),
        url('../fonts/icons/icomoon.woff?fkep4t') format('woff'),
        url('../fonts/icons/icomoon.svg?fkep4t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icons/icomoon.eot?7mlxs0');
  src:  url('../fonts/icons/icomoon.eot?7mlxs0#iefix') format('embedded-opentype'),
  url('../fonts/icons/icomoon.ttf?7mlxs0') format('truetype'),
  url('../fonts/icons/icomoon.woff?7mlxs0') format('woff'),
  url('../fonts/icons/icomoon.svg?7mlxs0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-thin-down:before {
  content: "\e902";
}
.icon-chevron-thin-right:before {
  content: "\e903";
}
.icon-cloud:before {
  content: "\e900";
}
.icon-download:before {
  content: "\e90e";
}
.icon-select-arrows:before {
  content: "\e90a";
}
.icon-triangle-down:before {
  content: "\e90b";
}
.icon-triangle-up:before {
  content: "\e90c";
}
.icon-upload:before {
  content: "\e912";
}
.icon-area-graph:before {
  content: "\e913";
}
.icon-clock:before {
  content: "\e901";
}
.icon-cog:before {
  content: "\e904";
}
.icon-cw:before {
  content: "\e910";
}
.icon-gauge:before {
  content: "\e906";
}
.icon-list:before {
  content: "\e905";
}
.icon-lock-open:before {
  content: "\e90d";
}
.icon-lock:before {
  content: "\e90f";
}
.icon-menu:before {
  content: "\e909";
}
.icon-share-alternative:before {
  content: "\e911";
}
.icon-sound-mix:before {
  content: "\e907";
}
.icon-text:before {
  content: "\e914";
}
.icon-tools:before {
  content: "\e908";
}
.icon-autorenew:before {
  content: "\e863";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-delete:before {
  content: "\e872";
}
.icon-do_not_disturb_alt:before {
  content: "\e611";
}
.icon-exit_to_app:before {
  content: "\e879";
}
.icon-flash_off:before {
  content: "\e3e6";
}
.icon-flash_on:before {
  content: "\e3e7";
}
.icon-info:before {
  content: "\e88e";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-playlist_add:before {
  content: "\e03b";
}
.icon-power_settings_new:before {
  content: "\e8ac";
}
.icon-public:before {
  content: "\e80b";
}
.icon-visibility:before {
  content: "\e8f4";
}
.icon-screen_rotation:before {
  content: "\e1c1";
}
.icon-view_comfy:before {
  content: "\e42a";
}
.icon-view_headline:before {
  content: "\e8ee";
}
.icon-visibility_off:before {
  content: "\e8f5";
}
.icon-wifi:before {
  content: "\e63e";
}