.dtr-title {
  font-weight: bold;

  &:after {
    content: ': ';
    font-weight: bold;
  }

}

.dataTable {

  th {
    position: relative;
    padding-right: 40px;

    &:after {
      font-family: 'icomoon' !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.75rem;
    }

    &.sorting:after {
      content: "\e90a";
    }

    &.sorting_asc:after {
      content: "\e90c";
    }

    &.sorting_desc:after {
      content: "\e90b";
    }

    &.no-sort {
      padding-right: 0.75rem;
    }

  }

}

.modal {

  td {
    position: static;
  }

}

.table-borderless {

  tr, th, td {
    border: 0;
  }

}

table .add-row {
  text-align: center;
  cursor: pointer;
  color: $med-gray;
  transition: all 1s;

  &:hover {
    background-color: $gray-lightest;
    color: $dark-gray;
  }

}

//Hides Dummy Template Row for Tables with an Add More button
.user-addition-template{
  display: none;
}