/*doc
---
title: Status Bar
name: 02-status-bar
category: Basics-Status Bar
---

```html_example
```

*/

.status-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-weight: bold;
  color: $dark-gray;
  font-size: 12px;
  margin-bottom: 20px;
  padding: 15px 25px 15px 25px;
  background-color: #fff;
  border-bottom: 1px solid $med-gray;
  position: relative;
  text-align: left;

  .status-items {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    margin-bottom: 0;

    li {
      position: relative;
      overflow: hidden;
      z-index: 2;
      padding: 5px 0;
    }

    li:not(:first-of-type) {
      margin-left: 20px;
      border-left: 1px solid $light-gray;
      padding: 5px 0 5px 20px;
    }

    li:last-of-type {
      margin-right: 0;
      border-right: 0;
      padding-right: 0;
    }

    span {
      font-weight: normal;
      display: block;
    }

  }

  #left-nav-toggle {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    span {
      font-size: 35px;
      color: $dark-gray;
      transition: all 0.3s linear;
    }

    span:hover,
    &.open span {
      color: $primary-color;
    }

  }

  .right-side {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    a {
      display: flex;
      color: $med-gray;
      text-decoration: none;
      transition: all 0.3s;

      &:hover {
        color: $primary-color;
      }

      &:first-of-type {
        padding-right: 10px;
        border-right: 1px solid $light-gray;
        margin-right: 10px;
      }

    }

  }

  #memory-util:after,
  #CPU-util:after {
    content: '%';
  }

}

a.no-fade {
  text-decoration: none;
  color: $dark-gray;
}

@include media-breakpoint-down(lg) {

  .status-bar {

    .status-items li {
      display: none;
    }

    #left-nav-toggle {
      display: block;
    }

  }

}
