// -------------------------------------------------
// Fonts
// -------------------------------------------------

/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans/OpenSans-Bold.woff?v=1.1.0") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans/OpenSans-Regular.woff?v=1.1.0") format("woff");
  font-weight: normal;
  font-style: normal;
}