/*doc
---
title: Primary Colors
name: 01-primary-colors
category: Basics-Colors
---

<div class="container-fluid">
  <div class="row">
    <div class="col-md-6" style="background: #96c93e; padding: 5%;">
      <p style="color: #fff;">
        $primary-color <br> Hex: #96c93e
      </p>
    </div>
    <div class="col-md-6" style="background: #182a53; padding: 5%;">
      <p style="color: #fff;">
        $secondary-color <br> Hex: #182a53
      </p>
    </div>
    <div class="col-md-4" style="background: #EEE; padding: 5%;">
      <p style="color: #000;">
        $light-gray <br> Hex: #EEEEEE
      </p>
    </div>
    <div class="col-md-4" style="background: #BFC0C0; padding: 5%;">
      <p style="color: #000;">
        $med-gray <br> Hex: #BFC0C0
      </p>
    </div>
    <div class="col-md-4" style="background: #50514F; padding: 5%;">
      <p style="color: #fff;">
        $dark-grey <br> Hex:  #50514F
      </p>
    </div>
  </div>
</div>

```none_example
```

*/

$primary-color: #96c93e;
$primary-color-hover: darken($primary-color, 10);
$secondary-color: #182a53;
$secondary-color-hover: darken($secondary-color, 10);
$light-gray: #EFF3F6;
$med-gray: #BFC0C0;
$dark-gray: #50514F;