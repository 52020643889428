//.port-overview {
//  display: flex;
//  flex-direction: row;
//  flex-wrap: nowrap;
//}
//.port-group {
//  display: flex;
//  flex-direction: column;
//  flex-wrap: wrap;
//  align-content: flex-start;
//  width: 100%;
//  height: 200px;
//  padding: 50px 0;
//
//  &:not(:last-of-type) {
//    border-right: 10px solid #273142;
//  }
//
//}
//.port-visual {
//  width: 25%;
//  height: 50%;
//  background: #fff;
//  border: 1px solid #273142;
//}

.port-overview {
  width: 100%;
  text-align: center;
}
.port-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  background: $light-gray;
  padding: 10px 10px 0;
  border-radius: $border-radius;
  justify-content: center;

  &:not(:last-of-type) {
    margin: 0 20px 20px 0;
  }

}
.port-visual,
.port-settings-visual .port-visual {
  width: 50px;
  height: 42px;
  margin-bottom: 30px;
  position: relative;
  cursor: pointer;
  background: #fff;

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  &:not(:first-of-type) {
    border-left: 0;
  }

  span {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      content: '';
      width: 12px;
      height: 3px;
      background: #d3d3d3;
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
    }

  }

  &:before {
    content: '';
    width: 40px;
    height: 30px;
    background: #d3d3d3;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:after {
    content: '';
    width: 25px;
    height: 3px;
    background: #d3d3d3;
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.port-not-connected {

    span:before,
    &:before,
    &:after {
      background: #d3d3d3;
    }

  }

  &.port-disabled {

    span:before,
    &:before,
    &:after {
      background: #f44336;
    }

  }

  &.port-Auto {

    span:before,
    &:before,
    &:after {
      background: #8BC34A;
    }
  }

  &.port-1Gbps {

    span:before,
    &:before,
    &:after {
      background: #8BC34A;
    }

  }

  &.port-2Gbps {

    span:before,
    &:before,
    &:after {
      background: #2196F3;
    }

  }

  &.port-5Gbps {

    span:before,
    &:before,
    &:after {
      background: #607D8B;
    }

  }

  &.port-10Gbps {

    span:before,
    &:before,
    &:after {
      background: #212121;
    }

  }

  &.port-10Mbps,
  &.port-100Mbps {

    span:before,
    &:before,
    &:after {
      background: #FF9800;
    }

  }

  &.port-POE,
  &.port-configurable {

    span:after {
      font-family: 'icomoon' !important;
      content: '\e3e7';
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
      color: #fff;
    }

  },

  &.port-configurable span:after {
      content: '\e80b';
  }

}

.port-settings-visual {
  padding: 0.5rem;
  width: 155px;
  cursor: pointer;
  transition: all 1s;

  &:hover {
    background-color: $gray-lightest;

    .port-visual {
      background-color: $gray-lightest;
    }

  }

  .port-visual {
    margin: auto;
    width: 75px;
    transition: all 1s;

    span {
      bottom: 0;
    }

  }

  p.lan-name {
    text-align: center;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p.lan-info {
    margin: 5px 0 0 0;
    font-size: 12px;
    color: $dark-gray;
    text-align: center;
  }

}